import type { SdkConfig } from '@/models/sdk';
import { api, initializeApi } from '@propify-tenant-client/services';

initializeApi();
class PhoenixSDKLoader {
  public init(config: SdkConfig = {}) {
    const targetElement = document.querySelector(config.elementSelector!);

    const shadowRoot = targetElement!.attachShadow({ mode: 'open' });

    const script = document.createElement('script');
    script.src = api.BASE_URL + '/sdk.js';

    const sc = document.createElement('script');
    sc.textContent =
      `
        function waitForSDK() {
          if (typeof phoenixSDK !== 'undefined') {
            phoenixSDK.init(` +
      JSON.stringify({ ...config, shadowRoot: true }) +
      `);
          } else {
            setTimeout(waitForSDK, 250);
          }
        }
        waitForSDK();
    `;
    shadowRoot.appendChild(script);
    shadowRoot.appendChild(sc);

    // this can be remove if we figure out how to load carousel styles inline
    const externalStyles = [
      'https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css',
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css',
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css',
    ];

    externalStyles.forEach((sheet) => {
      const styleElement = document.createElement('link');
      styleElement.href = sheet;
      styleElement.rel = 'stylesheet';
      styleElement.type = 'text/css';
      shadowRoot.appendChild(styleElement);
    });
  }
}

(window as any).phoenixSDKLoader = new PhoenixSDKLoader();

// eslint-disable-next-line no-console
console.log('> PhoenixSDKLoader loaded');
